<template>
  <div class="d-flex justify-center align-center page-height">
    <h1 class="text-center">
      <strong class="red--text text--lighten-1">Blimey! Page not found!</strong>
    </h1>
  </div>
</template>

<script>
export default {};
</script>